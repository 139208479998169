var render = function () {
  var _vm$form$tug_boat$use, _vm$form$tug_boat$use2, _vm$form$tug_boat$use3, _vm$form$tug_boat$use4, _vm$form$tug_boat$use5, _vm$form$tug_boat$use6, _vm$form$tug_boat$use7, _vm$form$tug_boat$use8, _vm$form$tug_boat$use9, _vm$form$tug_boat$use10, _vm$form$tug_boat$use11, _vm$form$tug_boat$use12, _this$form_realisasi, _vm$form$tug_boat$use13, _vm$form$tug_boat$use14;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "form_masuk"
    }
  }, [_vm.fetchLoading ? _c('b-progress', {
    key: "info",
    class: 'progress-bar-info',
    attrs: {
      "animated": "",
      "value": "100",
      "variant": "info"
    }
  }) : _vm._e(), _c('b-row', {
    attrs: {
      "align-h": "between",
      "align-v": "center"
    }
  }, [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('strong', [_vm._v("Ubah Status ")]), _vm.$router.currentRoute.params.id ? _c('b-dropdown', {
    attrs: {
      "id": "status",
      "variant": _vm.disableActionButton() ? 'danger' : _vm.formStatusVariant(_vm.form.schedule.form_status)
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function fn() {
        return [_vm.disableActionButton() ? _c('span', {
          domProps: {
            "textContent": _vm._s('IJIN BUNKER EXPIRED')
          }
        }) : _c('span', {
          domProps: {
            "textContent": _vm._s(_vm.form.schedule.form_status || 'Tidak ada status')
          }
        })];
      },
      proxy: true
    }], null, false, 611146085)
  }, [_vm.form.schedule.form_status !== 'KEGIATAN DIBATALKAN' ? _c('b-dropdown-header', {
    attrs: {
      "id": "dropdown-header-label"
    }
  }, [_c('small', {
    staticClass: "text-secondary"
  }, [_vm._v("Ubah status ke ")])]) : _vm._e(), !_vm.disableActionButton() && _vm.form.schedule.form_status !== 'KEGIATAN DIBATALKAN' && _vm.$store.state.app.roleName !== 'viewer' ? _c('b-dropdown-item', {
    attrs: {
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        return _vm.onUpdateStatus(true, _vm.form.schedule.form_status);
      }
    }
  }, [_c('b-badge', {
    attrs: {
      "variant": 'danger'
    }
  }, [_vm._v(" BATALKAN KEGIATAN ")])], 1) : _c('b-dropdown-item', {
    attrs: {
      "variant": "info"
    }
  }, [_vm.$store.state.app.roleName !== 'viewer' ? _c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm.disableActionButton() ? _c('span', [_vm._v(" Ijin Bunker Telah Expired ")]) : _c('span', [_vm._v(" Tidak Dapat Mengubah Status yang Telah Dibatalkan. ")])]) : _vm._e()], 1)], 1) : _vm._e()], 1), _vm.$router.currentRoute.params.id !== undefined ? _c('b-col', {
    staticClass: "text-center d-none d-sm-block",
    attrs: {
      "cols": "4"
    }
  }, [_c('h1', [_vm._v("TUG BOAT")]), _c('hr'), _c('b-badge', {
    attrs: {
      "variant": "light-primary"
    }
  }, [_vm._v(_vm._s(_vm.formName))])], 1) : _vm._e(), _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "cols": "4"
    }
  }, [_c('div', {
    staticClass: "float-right"
  }, [_c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(40, 199, 111, 0.15)',
      expression: "'rgba(40, 199, 111, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "variant": "outline-secondary",
      "to": _vm.$router.currentRoute.params.previous_link
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "ArrowLeftIcon"
    }
  }), _c('span', [_vm._v("Kembali")])], 1)], 1)]), _vm.$router.currentRoute.params.id !== undefined ? _c('b-col', {
    staticClass: "text-center d-block d-sm-none",
    attrs: {
      "cols": "12"
    }
  }, [_c('hr'), _c('h1', [_vm._v("CREATED BY")]), _c('hr')]) : _vm._e()], 1), _c('div', {
    staticClass: "mt-1"
  }, [_c('form-wizard', {
    key: "wizardKey",
    ref: "wizardFirst",
    class: _vm.isLastStep() ? _vm.dataID ? 'vertical-steps steps-transparent mb-3 sal-hide' : 'vertical-steps steps-transparent mb-3 sal' : 'vertical-steps steps-transparent mb-3 sal',
    attrs: {
      "color": "#7367F0",
      "title": null,
      "subtitle": null,
      "lazy": true,
      "layout": "vertical",
      "before-change": function beforeChange() {
        return true;
      },
      "finish-button-text": "Terbitkan Form Perencanaan Kedaatangan Kapal",
      "back-button-text": "Previous"
    },
    on: {
      "on-complete": _vm.formSubmitted,
      "on-change": _vm.onTabChange
    }
  }, [_c('tab-content', {
    attrs: {
      "title": _vm.wizard.first.step2.title,
      "icon": _vm.wizard.first.step2.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.first.step2.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.first.step2.content.subtitle) + ".")])])])], 1), _c('validation-observer', {
    ref: "tbbmRulesTugBoat",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Name Tug Boat *",
      "label-for": "vi-name_tug_boat"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Name Tug Boat",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-tug_boat-name",
            "suggestions": [{
              data: _vm.handlingTugBoat()
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Boat Name',
              disabled: _vm.permissionSuggestionTugBoatMain()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'tug_boat', 'name_tug_boat');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'vw_tug_boat/',
                model: 'tug_boat',
                currentSuggest: 'name_tug_boat'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var suggestion = _ref2.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.name_tug_boat)[0] + Array.from(suggestion.item.name_tug_boat)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.name_tug_boat, ", ").concat(suggestion.item.no_pkk_tug_boat))
                }
              }), suggestion.item.tug_status === 'Dalam Perencanaan' && suggestion.item.model_status === 'Tersedia' ? _c('div', [suggestion.item.tug_status === 'Dalam Perencanaan' ? _c('b-badge', {
                attrs: {
                  "variant": suggestion.item.model_status === 'Dalam Perencanaan' ? 'danger' : 'warning'
                }
              }, [_c('span', {
                domProps: {
                  "textContent": _vm._s("Sedang Direncanakan")
                }
              })]) : _vm._e()], 1) : _c('div', [suggestion.item.model_status === 'Sedang Digunakan' ? _c('b-badge', {
                attrs: {
                  "variant": suggestion.item.model_status === 'Sedang Digunakan' ? 'danger' : 'secondary'
                }
              }, [_c('span', {
                domProps: {
                  "textContent": _vm._s(suggestion.item.model_status)
                }
              })]) : _vm._e(), _c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(!suggestion.item.sedang_digunakan ? '' : " : ".concat(suggestion.item.sedang_digunakan)))
                }
              })], 1), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Updated AT ".concat(_vm.formatDateTime(suggestion.item.updated_at)))
                }
              })])])])])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.tug_boat.name_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "name_tug_boat", $$v);
            },
            expression: "form.tug_boat.name_tug_boat"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.tug_boatState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.tug_boatState]) + " ")])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.tug_boat.tug_boats.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.tug_boat.name_tug_boat ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'name_tug_boat' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }])
  }), _vm.tug_boatStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "GT ( Kapal ) *",
      "label-for": "vi-tug_boat-gt_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " GT ( Kapal ) *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var errors = _ref3.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": ""
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-tug_boat-gt_tug_boat",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionSuggestionTugBoat(),
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.tug_boat.gt_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "gt_tug_boat", $$v);
            },
            expression: "form.tug_boat.gt_tug_boat"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "LOA ( Kapal ) *",
      "label-for": "vi-tug_boat-loa_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " LOA ( Kapal ) *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "M"
          }
        }, [_c('b-form-input', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-tug_boat-loa_tug_boat",
            "type": "number",
            "placeholder": "78,12 (comma manual)",
            "readonly": _vm.permissionSuggestionTugBoat()
          },
          model: {
            value: _vm.form.tug_boat.loa_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "loa_tug_boat", $$v);
            },
            expression: "form.tug_boat.loa_tug_boat"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Capt. Name *",
      "label-for": "vi-tug_boat-captain_name_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Capt. Name *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-tug_boat-captain_name_tug_boat",
            "state": errors.length > 0 ? false : null,
            "type": "text",
            "placeholder": "Full Capt. Name",
            "readonly": _vm.permissionSuggestionTugBoat()
          },
          model: {
            value: _vm.form.tug_boat.captain_name_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "captain_name_tug_boat", $$v);
            },
            expression: "form.tug_boat.captain_name_tug_boat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Capt. Telp *",
      "label-for": "vi-tug_boat-captain_telp_tug_boat"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Capt. Telp *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref6) {
        var errors = _ref6.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-tug_boat-captain_telp_tug_boat",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "placeholder": "081145778613",
            "readonly": _vm.permissionSuggestionTugBoat()
          },
          model: {
            value: _vm.form.tug_boat.captain_telp_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "captain_telp_tug_boat", $$v);
            },
            expression: "form.tug_boat.captain_telp_tug_boat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), ((_vm$form$tug_boat$use = _vm.form.tug_boat.user) === null || _vm$form$tug_boat$use === void 0 ? void 0 : (_vm$form$tug_boat$use2 = _vm$form$tug_boat$use.abilities[0]) === null || _vm$form$tug_boat$use2 === void 0 ? void 0 : _vm$form$tug_boat$use2.action) != 'TBBM JETTY 3A' ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor PKK *",
      "label-for": "vi-no_pkk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Nomor PKK *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref7) {
        var errors = _ref7.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-no_pkk",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenantAndSAL(),
            "placeholder": "Cth: PKK.DN.IDSRI.2208.000123"
          },
          model: {
            value: _vm.form.schedule.no_pkk_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pkk_tug_boat", $$v);
            },
            expression: "form.schedule.no_pkk_tug_boat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.schedule.no_pkk_tug_boat ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'no_pkk_tug_boat' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 3133624723)
  })], 1)], 1) : _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor PKK *",
      "label-for": "vi-no_pkk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Nomor PKK *",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-input', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-no_pkk",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSuggestionTugBoat(),
            "placeholder": "Cth: PKK.DN.IDSRI.2208.000123"
          },
          model: {
            value: _vm.form.tug_boat.no_pkk_tug_boat,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "no_pkk_tug_boat", $$v);
            },
            expression: "form.tug_boat.no_pkk_tug_boat"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.tug_boat.no_pkk_tug_boat ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'no_pkk_tug_boat' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Perusahaan Shipping Agent *",
      "label-for": "vi-dm_shipping_agent-shipping_name"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": " Perusahaan Shipping Agent *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref9) {
        var errors = _ref9.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-dm_shipping_agent-shipping_name",
            "get-suggestion-value": _vm.getSuggestionValue,
            "suggestions": [{
              data: _vm.form.tug_boat.dm_shipping_agent.dm_shipping_agents || []
            }],
            "state": errors.length > 0 ? false : null,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control form-input',
              placeholder: 'Cth: PT LINTAS SERAM M',
              disabled: _vm.permissionSuggestionTugBoat()
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'tug_boat', 'dm_shipping_agent', 'dm_shipping_agent.shipping_name');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_shipping_agent/',
                model: 'tug_boat',
                currentSuggest: 'tug_boat',
                childSuggesst: 'dm_shipping_agent.shipping_name'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var suggestion = _ref10.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                staticClass: "my-suggestion-item"
              }, [_vm._v(_vm._s(suggestion.item.shipping_name))])])]), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(_vm.dateFormat(suggestion.item.created_at)))
                }
              })])])], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.form.tug_boat.dm_shipping_agent.shipping_name,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat.dm_shipping_agent, "shipping_name", $$v);
            },
            expression: "\n                          form.tug_boat.dm_shipping_agent.shipping_name\n                        "
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_shipping_agentState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_shipping_agentState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.tug_boat.dm_shipping_agent.dm_shipping_agents.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.tug_boat.dm_shipping_agent.shipping_name ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'shipping_name' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }])
  }), _vm.dm_shipping_agentStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)], 1), !_vm.isExistDetailID && !_vm.form.tug_boat.isNew ? _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "float-left"
  }, [_c('b-button', {
    staticClass: "mr-1",
    attrs: {
      "variant": "outline-danger"
    },
    on: {
      "click": function click($event) {
        return _vm.breakSuggestionValueTB();
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Ubah Data Master Kapal Yang otomatis Teriisi ( Duplicate & Create as a New Data )')
    }
  })], 1), _c('span', [_c('small', [_c('strong', [_vm._v("Abaikan jika data master kapal yang otomatis teriisi sudah benar")])])])], 1)]) : _vm._e()], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-row', [_vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' && _vm.$store.state.app.roleName !== 'security' && _vm.isExistDetailID && _vm.statePayloadDMKTB === null && !_vm.statePayloadDMKTBMaster || _vm.statePayloadDMKTB === _vm.mapStatePayloadDMK.UPDATE_WITH_NEW_DATA ? _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "outline-danger",
      "block": "",
      "disabled": !_vm.form.tug_boat.no_pkk_tug_boat || !_vm.editAble(_vm.user_id)
    },
    on: {
      "click": function click($event) {
        return _vm.updateDMKTB({
          isNotEdit: false,
          payloadDMK: _vm.mapStatePayloadDMK.UPDATE_WITH_NEW_DATA
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Duplicate data tug boat and Create as a new data")])], 1), _c('span', [_vm._v("Contoh kasus: ada pembaharuan nomor PKK")])], 1) : _vm._e(), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' && _vm.$store.state.app.roleName !== 'security' && _vm.isExistDetailID && _vm.statePayloadDMKTB === null && !_vm.statePayloadDMKTBMaster || _vm.statePayloadDMKTB === _vm.mapStatePayloadDMK.UPDATE_ON_EXIST_DATA ? _c('b-col', {
    staticClass: "text-left",
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "outline-warning",
      "block": "",
      "disabled": !_vm.form.tug_boat.no_pkk_tug_boat || !_vm.editAble(_vm.user_id)
    },
    on: {
      "click": function click($event) {
        return _vm.updateDMKTB({
          isNotEdit: false,
          payloadDMK: _vm.mapStatePayloadDMK.UPDATE_ON_EXIST_DATA
        });
      }
    }
  }, [_c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "PenToolIcon"
    }
  }), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Revisi data tug boat, Jika ada data yang tidak sesuai.")])], 1), _c('span', [_vm._v("Contoh kasus: kesalahan penulisan")])], 1) : _vm._e(), _vm.statePayloadDMKTB ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "d-block mt-2",
    attrs: {
      "variant": "danger",
      "block": ""
    },
    on: {
      "click": function click($event) {
        _vm.updateDMKTB({
          isNotEdit: true,
          payloadDMK: null
        });
        _vm.fetchData();
      }
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "XCircleIcon"
    }
  }), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('')
    }
  })], 1)], 1) : _vm._e(), _vm.statePayloadDMKTB ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "d-block mt-2",
    attrs: {
      "variant": "success",
      "block": "",
      "disabled": _vm.loadingDupRevTB
    },
    on: {
      "click": function click($event) {
        return _vm.saveToUpdateDMKTB();
      }
    }
  }, [!_vm.loadingDupRevTB ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingDupRevTB ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('SIMPAN')
    }
  })], 1)], 1) : _vm._e(), _vm.statePayloadDMKTBMaster ? _c('b-col', {
    attrs: {
      "md": "2",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "danger"
    },
    on: {
      "click": function click($event) {
        _vm.updateDMKTBMaster({
          isNotEdit: true,
          payloadDMK: null
        });
        _vm.fetchData();
      }
    }
  }, [_c('feather-icon', {
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "XCircleIcon"
    }
  }), _c('span')], 1)], 1) : _vm._e(), _vm.statePayloadDMKTBMaster ? _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "block": "",
      "variant": "success",
      "disabled": _vm.loadingDupRevTB
    },
    on: {
      "click": function click($event) {
        return _vm.jwtGantiKapalTB();
      }
    }
  }, [!_vm.loadingDupRevTB ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingDupRevTB ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', [_vm._v("Simpan")])], 1)], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v("Schedule")])], 1)]), _c('b-row', [_vm.form.schedule.jenis_kapal ? _c('b-col', {
    key: "kegiatan1key",
    attrs: {
      "md": _vm.form.schedule.kegiatan !== 'BUNKER' ? '3' : '6',
      "cols": _vm.form.schedule.kegiatan !== 'BUNKER' ? '6' : '6'
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Pilih Kegiatan *",
      "label-for": "vi-schedule-kegiatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Kegiatan",
      "vid": "vi-schedule-kegiatan",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-kegiatan",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.form.schedule.jenis_kapal !== 'Tug Boat' ? _vm.kegiatan : _vm.kegiatan.filter(function (data) {
              return data.value === 'TAMBAT' || data.value === 'BUNKER';
            }),
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": _vm.permissionTenant(),
            "label": "text",
            "placeholder": "Pilih Kegiatan",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusKegiatan();
            }
          },
          model: {
            value: _vm.form.schedule.kegiatan,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan", $$v);
            },
            expression: "form.schedule.kegiatan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 249001002)
  })], 1)], 1) : _vm._e(), _vm.form.schedule.jenis_kapal && _vm.form.schedule.kegiatan !== 'BUNKER' ? _c('b-col', {
    key: "kegiatan2key",
    attrs: {
      "md": "3",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tambah Kegiatan",
      "label-for": "vi-schedule-tambah-kegiatan"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tambah Kegiatan",
      "vid": "vi-schedule-tambah-kegiatan",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref12) {
        var errors = _ref12.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-tambah-kegiatan",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.kegiatan.filter(function (data) {
              return data.value === 'BUNKER';
            }),
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null') && option.value.includes('BUNKER');
            },
            "disabled": _vm.permissionTenant(),
            "label": "text",
            "placeholder": "Tambah Kegiatan Bunker",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusKegiatan();
            }
          },
          model: {
            value: _vm.form.schedule.kegiatan_tambahan,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_tambahan", $$v);
            },
            expression: "form.schedule.kegiatan_tambahan"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3605018801)
  })], 1)], 1) : _vm._e(), _vm.form.schedule.jenis_kapal === 'Tug Boat' ? _c('b-col', {
    attrs: {
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "asal Kapal",
      "label-for": "vi-asal_kapal"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "asal Kapal",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-asal-kapal",
            "suggestions": [{
              data: _vm.form.dm_asal_kapal.dm_asal_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValue,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'asal Kapal'
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'dm_asal_kapal', 'asal');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_asal_kapal/',
                model: 'dm_asal_kapal',
                currentSuggest: 'asal'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref14) {
              var suggestion = _ref14.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.asal)[0] + Array.from(suggestion.item.asal)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.asal))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.dm_asal_kapal.asal,
            callback: function callback($$v) {
              _vm.$set(_vm.form.dm_asal_kapal, "asal", $$v);
            },
            expression: "form.dm_asal_kapal.asal"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_asal_kapalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_asal_kapalState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_asal_kapal.dm_asal_kapals.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_asal_kapal.asal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'asal' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 3208549102)
  }), _vm.dm_asal_kapalStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1) : _vm._e(), _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Sandar Tug Boat *",
      "label-for": "vi-rencana_sandar"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Sandar Tug Boat *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [!_vm.permissionTenantAndSAL() ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Rencana Sandar Tug Boat *",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpconfigRevisi : _vm.dpconfig
          },
          model: {
            value: _vm.form.tug_boat.rencana_sandar,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "rencana_sandar", $$v);
            },
            expression: "form.tug_boat.rencana_sandar"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-rencana_sandar",
            "value": _vm.formatDateTime(_vm.form.tug_boat.rencana_sandar),
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('Hulu Migas', ''),
            "state": errors.length > 0 ? false : null
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "No Pindah",
      "label-for": "vi-schedule-no_pindah_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "No Pindah",
      "vid": "No Pindah",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_pindah_tb",
            "state": errors.length > 0 ? false : null,
            "placeholder": 'Nomor Pindah (opsional)'
          },
          model: {
            value: _vm.form.tug_boat.no_pindah_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "no_pindah_tb", $$v);
            },
            expression: "form.tug_boat.no_pindah_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Posisi Jetty *",
      "label-for": "vi-schedule-rencana_position_jetty"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Posisi Jetty",
      "vid": "Rencana Posisi Jetty",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref17) {
        var errors = _ref17.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-rencana_position_jetty",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": _vm.permissionTenantAndSAL(),
            "label": "text",
            "placeholder": "Pilih Rencana Posisi Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.tug_boat.rencana_position_jetty,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "rencana_position_jetty", $$v);
            },
            expression: "form.tug_boat.rencana_position_jetty"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "3",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Rencana Baris Posisi Jetty *",
      "label-for": "vi-schedule-rencana_baris_position_jetty"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Rencana Baris Posisi Jetty",
      "vid": "Rencana Baris Posisi Jetty",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref18) {
        var errors = _ref18.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-rencana_baris_position_jetty",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_baris_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": _vm.permissionTenantAndSAL(),
            "label": "text",
            "placeholder": "Pilih Rencana Baris Posisi Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.tug_boat.rencana_baris_position_jetty,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "rencana_baris_position_jetty", $$v);
            },
            expression: "\n                              form.tug_boat.rencana_baris_position_jetty\n                            "
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1), _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' && _vm.$store.state.app.roleName !== 'security' ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.disableActionButton() ? _c('ExpirationAlert') : _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "primary",
      "block": "",
      "disabled": _vm.loadingTab1 || !_vm.editAble(_vm.user_id)
    },
    on: {
      "click": function click($event) {
        return _vm.updateTab1();
      }
    }
  }, [!_vm.loadingTab1 ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "DatabaseIcon"
    }
  }) : _vm._e(), _vm.loadingTab1 ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan / Ubah Main Boat")])], 1)], 1) : _vm._e()], 1)], 1)], 1)], 1)], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1), _c('tab-content', {
    attrs: {
      "title": "Crew Kapal",
      "icon": "feather icon-anchor"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Crew Kapal")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v("Untuk keperluan monitoring jumlah crew kapal yang masuk area PT SAL.")])])])], 1), _c('validation-observer', {
    ref: "refVisitor",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "6",
      "cols": "12"
    }
  }, [_c('b-card', [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Jumlah Crew Kapal",
      "label-for": "vi-schedule-count_crew_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Jumlah Crew Kapal",
      "vid": "Jumlah Crew Kapal",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref19) {
        var errors = _ref19.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-count_crew_tb",
            "state": errors.length > 0 ? false : null,
            "type": "number",
            "readonly": _vm.permissionTenant(),
            "placeholder": 'Jumlah Crew Kapal'
          },
          model: {
            value: _vm.form.schedule.count_crew_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "count_crew_tb", $$v);
            },
            expression: "form.schedule.count_crew_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }])
  })], 1)], 1)], 1)], 1)], 1)], 1), _vm.isExistDetailID && !_vm.permissionTenantAndSAL() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRealisasiCrew
    },
    on: {
      "click": function click($event) {
        return _vm.saveCrewKapal();
      }
    }
  }, [!_vm.loadingRealisasiCrew ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRealisasiCrew ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Jumlah Crew Kapal")])], 1) : _vm._e()], 1), _c('div', {
    staticClass: "mb-1"
  })], 1), _vm.dataID && _vm.form.schedule.kegiatan !== 'BUNKER' ? _c('tab-content', {
    attrs: {
      "title": "SPOG",
      "icon": "feather icon-unlock"
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("SPOG")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v("Document Realisasi SPOG.")])])])], 1), _c('span', [_c('validation-observer', {
    ref: "refSAL",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [(_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id) && ((_vm$form$tug_boat$use3 = _vm.form.tug_boat.user) === null || _vm$form$tug_boat$use3 === void 0 ? void 0 : (_vm$form$tug_boat$use4 = _vm$form$tug_boat$use3.abilities[0]) === null || _vm$form$tug_boat$use4 === void 0 ? void 0 : _vm$form$tug_boat$use4.action) == 'TBBM JETTY 3A' ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor SPOG",
      "label-for": "vi-schedule-no_spog"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG",
      "vid": "Nomor SPOG",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref20) {
        var errors = _ref20.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": _vm.$router.currentRoute.params.id ? '' : 'Opsional'
          },
          model: {
            value: _vm.form.schedule.no_spog_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog_tb", $$v);
            },
            expression: "form.schedule.no_spog_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3413747674)
  })], 1)], 1) : _vm._e(), (_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id) && ((_vm$form$tug_boat$use5 = _vm.form.tug_boat.user) === null || _vm$form$tug_boat$use5 === void 0 ? void 0 : (_vm$form$tug_boat$use6 = _vm$form$tug_boat$use5.abilities[0]) === null || _vm$form$tug_boat$use6 === void 0 ? void 0 : _vm$form$tug_boat$use6.action) != 'TBBM JETTY 3A' ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor SPOG ( Kedatangan )",
      "label-for": "vi-schedule-no_spog"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG ( Kedatangan )",
      "vid": "Nomor SPOG ( Kedatangan )",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref21) {
        var errors = _ref21.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": _vm.$router.currentRoute.params.id ? '' : 'Opsional'
          },
          model: {
            value: _vm.form.schedule.no_spog_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog_tb", $$v);
            },
            expression: "form.schedule.no_spog_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3413747674)
  })], 1)], 1) : _vm._e(), (_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id) && ((_vm$form$tug_boat$use7 = _vm.form.tug_boat.user) === null || _vm$form$tug_boat$use7 === void 0 ? void 0 : (_vm$form$tug_boat$use8 = _vm$form$tug_boat$use7.abilities[0]) === null || _vm$form$tug_boat$use8 === void 0 ? void 0 : _vm$form$tug_boat$use8.action) != 'TBBM JETTY 3A' ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor SPOG ( keberangkatan )",
      "label-for": "vi-schedule-no_spog_tb_brk"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor SPOG ( keberangkatan )",
      "vid": "Nomor SPOG ( keberangkatan )",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref22) {
        var errors = _ref22.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_spog_tb_brk",
            "state": errors.length > 0 ? false : null,
            "placeholder": "Nomor SPOG ( Keberangkatan )"
          },
          model: {
            value: _vm.form.schedule.no_spog_tb_brk,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_spog_tb_brk", $$v);
            },
            expression: "form.schedule.no_spog_tb_brk"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4170320568)
  })], 1)], 1) : _vm._e(), _vm._v(" "), (_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id) && ((_vm$form$tug_boat$use9 = _vm.form.tug_boat.user) === null || _vm$form$tug_boat$use9 === void 0 ? void 0 : (_vm$form$tug_boat$use10 = _vm$form$tug_boat$use9.abilities[0]) === null || _vm$form$tug_boat$use10 === void 0 ? void 0 : _vm$form$tug_boat$use10.action) === 'TBBM JETTY 3A' ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "File SPOG",
      "label-for": "vi-schedule-no_spog_file"
    }
  }, [_c('BFormFile', {
    attrs: {
      "id": "vi-schedule-no_spog_file",
      "placeholder": "Choose a file SPOG or drop it here...",
      "drop-placeholder": "Drop file here...",
      "no-drop": "",
      "accept": ".xls, .xlsx, .pdf, .jpeg, .jpg, .png"
    },
    model: {
      value: _vm.form.schedule.no_spog_file_tb,
      callback: function callback($$v) {
        _vm.$set(_vm.form.schedule, "no_spog_file_tb", $$v);
      },
      expression: "form.schedule.no_spog_file_tb"
    }
  })], 1), _vm._l(_vm.no_spog_file_tb, function (item, index) {
    return _c('app-timeline-item', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
    }), _c('p', [_c('b-img', {
      staticClass: "mr-1",
      attrs: {
        "src": require('@/assets/images/icons/xls.png'),
        "height": "auto",
        "width": "20"
      }
    }), _c('span', {
      staticClass: "align-bottom"
    }, [_c('a', {
      staticStyle: {
        "color": "grey"
      },
      attrs: {
        "href": "".concat(_vm.BACKEND_URL).concat(item.path, "/").concat(item.unique_name),
        "target": "_blank",
        "rel": "noopener noreferrer"
      }
    }, [_vm._v(_vm._s(item.original_name))])])], 1), _c('small', {
      staticClass: "text-muted"
    }, [_vm._v("Size " + _vm._s(item.size) + " "), _c('strong', [_vm._v("[ click name file, to preview.]")])])]);
  })], 2) : _vm._e(), _vm.$store.state.app.roleName !== 'viewer' ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "mt-1 cursor-pointer",
    attrs: {
      "variant": !_vm.form.schedule.no_rpkro_tb ? 'warning' : 'primary',
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body",
    on: {
      "click": function click($event) {
        return _vm.copyText(_vm.form.schedule.no_rpkro_tb);
      }
    }
  }, [_c('strong', [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AnchorIcon"
    }
  }), _c('span', {
    domProps: {
      "textContent": _vm._s(!_vm.form.schedule.no_rpkro_tb ? 'Nomor RPKRO Belum terbit' : "Nomor RPKRO : ".concat(_vm.form.schedule.no_rpkro_tb))
    }
  })], 1)])]), _vm.isExistDetailID && !_vm.permissionTenant() ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRealisasiKegiatan || !_vm.editAble(_vm.user_id)
    },
    on: {
      "click": function click($event) {
        return _vm.saveRealisasiKegiatan('SPOG');
      }
    }
  }, [!_vm.loadingRealisasiKegiatan ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRealisasiKegiatan ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi Kegiatan")])], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e(), _vm.form.schedule.jenis_kapal && (_vm.form.schedule.kegiatan === 'BUNKER' || _vm.form.schedule.kegiatan_tambahan === 'BUNKER') ? _c('tab-content', {
    attrs: {
      "id": "ijin-bunker",
      "title": "Ijin Bunker",
      "icon": _vm.wizard.first.step4.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v("Document File Ijin Bunker")]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.first.step4.content.subtitle) + ".")])])])], 1), _vm.loadingFileStorage ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('validation-observer', {
    ref: "tbbmRulesFileBunker",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nomor Ijin Bunker",
      "label-for": "vi-schedule-no_ijin_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor Ijin Bunker",
      "vid": "Nomor Ijin Bunker",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref23) {
        var errors = _ref23.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_ijin_bunker",
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionTenant(),
            "placeholder": _vm.$router.currentRoute.params.id ? 'Nomor Ijin BUnker' : 'Nomor Ijin BUnker'
          },
          model: {
            value: _vm.form.schedule.no_ijin_bunker,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_ijin_bunker", $$v);
            },
            expression: "form.schedule.no_ijin_bunker"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 4182253146)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Volume (Total Bunker)",
      "label-for": "vi-dm_river_boat-total_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Volume (Total Bunker) *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref24) {
        var errors = _ref24.errors;
        return [_c('b-input-group', {
          attrs: {
            "append": "Liter"
          }
        }, [_c('cleave', {
          staticClass: "form-control",
          attrs: {
            "id": "vi-dm_river_boat-total_bunker",
            "raw": true,
            "options": _vm.options.number,
            "readonly": _vm.permissionTenantChild(),
            "placeholder": "1.000 (dot automaticaly)"
          },
          model: {
            value: _vm.form.schedule.total_bunker,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "total_bunker", $$v);
            },
            expression: "form.schedule.total_bunker"
          }
        })], 1), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 453246378)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Expired Date Ijin Bunker",
      "label-for": "vi-exp_ijin_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Expired Date Ijin Bunker *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref25) {
        var errors = _ref25.errors;
        return [(!_vm.$can('manage', 'special') || _vm.$can('manage', 'all') || _vm.$can('SAL', '')) && !_vm.disableActionButton() ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "Expired Date Ijin Bunker",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpExpIjinBunkerID : _vm.dpExpIjinBunker
          },
          on: {
            "on-change": _vm.onStartChangedpExpIjinBunker
          },
          model: {
            value: _vm.form.schedule.exp_ijin_bunker,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "exp_ijin_bunker", $$v);
            },
            expression: "form.schedule.exp_ijin_bunker"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-exp_ijin_bunker",
            "value": _vm.formatDateTime(_vm.form.schedule.exp_ijin_bunker, 'D MMMM YYYY'),
            "readonly": _vm.permissionTenant(),
            "state": errors.length > 0 ? false : null
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 351732041)
  })], 1)], 1), _c('b-col', [_c('b-form-group', {
    attrs: {
      "label": "",
      "label-for": "vi-schedule-ijin_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Ijin Bunker",
      "vid": "Ijin Bunker",
      "rules": {
        required: true
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref26) {
        var errors = _ref26.errors;
        return [_c('BFormFile', {
          attrs: {
            "placeholder": "Choose a file Ijin Bunker or drop it here...",
            "drop-placeholder": "Drop file here...",
            "state": errors.length > 0 ? false : null,
            "accept": ".xls, .xlsx, .pdf, .jpeg, .jpg, .png",
            "no-drop": ""
          },
          model: {
            value: _vm.form.schedule.no_ijin_bunker_file,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_ijin_bunker_file", $$v);
            },
            expression: "form.schedule.no_ijin_bunker_file"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm._l(_vm.no_ijin_bunker_file, function (item, index) {
          return _c('app-timeline-item', {
            key: index
          }, [_c('div', {
            staticClass: "d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0"
          }), _c('p', [_c('b-img', {
            staticClass: "mr-1",
            attrs: {
              "src": require('@/assets/images/icons/xls.png'),
              "height": "auto",
              "width": "20"
            }
          }), _c('span', {
            staticClass: "align-bottom"
          }, [_c('a', {
            staticStyle: {
              "color": "grey"
            },
            attrs: {
              "href": item.download_link,
              "target": "_blank",
              "rel": "noopener noreferrer"
            }
          }, [_vm._v(_vm._s(item.original_name))])])], 1), _c('small', {
            staticClass: "text-muted"
          }, [_vm._v("Size " + _vm._s(item.size) + " "), _c('strong', [_vm._v("[ click name file, to preview.]")])])]);
        })];
      }
    }], null, false, 894219283)
  })], 1)], 1), !_vm.permissionTenant() && _vm.isExistDetailID ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('br'), _vm.disableActionButton() ? _c('ExpirationAlert') : _vm.$store.state.app.roleName !== 'viewer' && _vm.$store.state.app.roleName !== 'jetty man' ? _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingSaveTenant || !_vm.editAble(_vm.user_id)
    },
    on: {
      "click": function click($event) {
        return _vm.saveIjinBunker();
      }
    }
  }, [!_vm.loadingSaveTenant ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingSaveTenant ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Simpan Ijin Bunker')
    }
  })], 1) : _vm._e()], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('br'), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Realisasi Bunker")]), _c('hr'), _c('b-row', [_vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Mulai Berkegiatan Bunker",
      "label-for": "vi-schedule-kegiatan_start_time_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Mulai Berkegiatan Bunker",
      "vid": "Waktu Mulai Berkegiatan Bunker",
      "rules": {
        required_if: 'Waktu Selesai Berkegiatan Bunker'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref27) {
        var errors = _ref27.errors;
        return [_vm.readonlyFieldTabAdminSAL() ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpWktBunkerID : _vm.dpWktBunker
          },
          on: {
            "on-change": _vm.onStartChangedpWktBunker
          },
          model: {
            value: _vm.form.schedule.kegiatan_start_time_bunker,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_start_time_bunker", $$v);
            },
            expression: "form.schedule.kegiatan_start_time_bunker"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_start_time_bunker",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_bunker),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": ""
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1554534550)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('manage', 'special') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Selesai Berkegiatan Bunker",
      "label-for": "vi-schedule-kegiatan_end_time_bunker"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Selesai Berkegiatan Bunker",
      "vid": "Waktu Selesai Berkegiatan Bunker",
      "rules": {
        required_if: 'Waktu Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref28) {
        var errors = _ref28.errors;
        return [_vm.readonlyFieldTabAdminSAL() ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpWktBunker.end
          },
          on: {
            "on-change": _vm.onEndChangedpWktBunker
          },
          model: {
            value: _vm.form.schedule.kegiatan_end_time_bunker,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_end_time_bunker", $$v);
            },
            expression: "form.schedule.kegiatan_end_time_bunker"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_end_time_bunker",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_bunker),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": ""
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1035133789)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || (_vm.$can('SAL', '') || _vm.$can('TBBM JETTY 3A', '')) && _vm.$router.currentRoute.params.id ? _c('b-col', {
    staticClass: "text-center",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('br'), _vm.disableActionButton() ? _c('ExpirationAlert') : _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingSaveTenant
    },
    on: {
      "click": function click($event) {
        return _vm.saveRealisasiBunker();
      }
    }
  }, [!_vm.loadingSaveTenant ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingSaveTenant ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle",
    domProps: {
      "textContent": _vm._s('Simpan Realisasi Bunker')
    }
  })], 1)], 1) : _vm._e()], 1)], 1) : _vm._e()], 1) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e(), _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' || _vm.$store.state.app.roleName === 'security' || _vm.$can('TBBM JETTY 3A', '') ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.second.step1.title,
      "icon": _vm.wizard.second.step1.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.second.step1.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.second.step1.content.subtitle) + ".")])])])], 1), (_vm$form$tug_boat$use11 = _vm.form.tug_boat.user) !== null && _vm$form$tug_boat$use11 !== void 0 && _vm$form$tug_boat$use11.abilities.some(function (ability) {
    return ability.action === 'TBBM JETTY 3A';
  }) ? _c('validation-observer', {
    ref: "refRKA2",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v("Tug Boat")]), _c('h3', [_vm._v("Realisasi Kapal " + _vm._s(_vm.form.tug_boat.name_tug_boat))])], 1)])]) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": !_vm.permissionSalOnly() ? '6' : '6',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Posisi Jetty",
      "label-for": "vi-schedule-position_jetty"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Posisi Jetty",
      "vid": "Posisi Jetty",
      "rules": {
        required_if: 'Waktu Kapal Sandar'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref29) {
        var errors = _ref29.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-position_jetty",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', '') && !_vm.$store.state.app.roleName === 'jetty man' || _vm.$can('TBBM JETTY 3A', ''),
            "label": "text",
            "placeholder": "Pilih Posisi Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.schedule.position_jetty,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "position_jetty", $$v);
            },
            expression: "form.schedule.position_jetty"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1389540238)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": !_vm.permissionSalOnly() ? '6' : '6',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Posisi Baris Jetty",
      "label-for": "vi-schedule-position_jetty"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Posisi Baris Jetty",
      "vid": "Posisi Baris Jetty",
      "rules": {
        required_if: 'Waktu Kapal Sandar'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref30) {
        var errors = _ref30.errors;
        return [_c('v-select', {
          attrs: {
            "id": "vi-schedule-position_jetty",
            "dir": _vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',
            "options": _vm.jetty_baris_positions,
            "reduce": function reduce(option) {
              return option.value;
            },
            "selectable": function selectable(option) {
              return !option.value.includes('null');
            },
            "disabled": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', '') && _vm.$store.state.app.roleName !== 'jetty man' && _vm.$store.state.app.roleName !== 'security' || _vm.$can('TBBM JETTY 3A', ''),
            "label": "text",
            "placeholder": "Pilih Posisi Baris Jetty",
            "item-value": "value",
            "item-text": "text"
          },
          on: {
            "search:focus": function searchFocus($event) {
              return _vm.onFocusJetty();
            }
          },
          model: {
            value: _vm.form.schedule.position_baris_jetty,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "position_baris_jetty", $$v);
            },
            expression: "form.schedule.position_baris_jetty"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3056379317)
  })], 1)], 1) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '4' : '4',
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Sandar",
      "label-for": "vi-tug_boat-tug_sandar_start_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Sandar",
      "vid": "Waktu Kapal Sandar",
      "rules": {
        required_if: 'Waktu Kapal Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref31) {
        var errors = _ref31.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' || _vm.$store.state.app.roleName === 'security' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL SANDAR",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.tug_boat.tug_sandar_start_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "tug_sandar_start_time", $$v);
            },
            expression: "form.tug_boat.tug_sandar_start_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-tug_boat-tug_sandar_start_time",
            "value": _vm.formatDateTime(_vm.form.tug_boat.tug_sandar_start_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly() || _vm.$can('TBBM JETTY 3A', ''),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3371730000)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '4' : '4',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tujuan Kapal",
      "label-for": "vi-asal_kapal"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tujuan Kapal",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref32) {
        var errors = _ref32.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-tujuan-kapal",
            "suggestions": [{
              data: _vm.form.dm_asal_kapal.dm_asal_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValueTKTB,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Tujuan Kapal',
              disabled: _vm.$can('TBBM JETTY 3A', '')
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'schedule', 'tujuan_kapal_tb');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_asal_kapal/',
                model: 'dm_asal_kapal',
                currentSuggest: 'asal',
                flagTujuanOps: 'tujuan_kapal_tb_is_new'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref33) {
              var suggestion = _ref33.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.asal)[0] + Array.from(suggestion.item.asal)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.asal))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.schedule.tujuan_kapal_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "tujuan_kapal_tb", $$v);
            },
            expression: "form.schedule.tujuan_kapal_tb"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_asal_kapalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_asal_kapalState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_asal_kapal.dm_asal_kapals.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_asal_kapal.asal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'asal' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 2193639524)
  }), _vm.dm_asal_kapalStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '4' : '4',
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Berangkat",
      "label-for": "vi-tug_boat-tug_sandar_end_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Berangkat",
      "vid": "Waktu Kapal Berangkat",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref34) {
        var errors = _ref34.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'security' || _vm.$store.state.app.roleName === 'jetty man' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL BERANGKAT",
            "state": errors.length > 0 ? false : null,
            "config": _vm.configs.end
          },
          on: {
            "on-change": _vm.onEndChange
          },
          model: {
            value: _vm.form.tug_boat.tug_sandar_end_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "tug_sandar_end_time", $$v);
            },
            expression: "form.tug_boat.tug_sandar_end_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-tug_boat-tug_sandar_end_time",
            "value": _vm.formatDateTime(_vm.form.tug_boat.tug_sandar_end_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly() || _vm.$can('TBBM JETTY 3A', ''),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1924731550)
  })], 1)], 1) : _vm._e(), _vm.$store.state.app.roleName !== 'viewer' ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' || _vm.$store.state.app.roleName === 'security' ? _c('b-button', {
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRelRKA2
    },
    on: {
      "click": function click($event) {
        return _vm.saveRKA2();
      }
    }
  }, [!_vm.loadingRelRKA2 ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRelRKA2 ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi Sandar")])], 1) : _vm._e()], 1) : _vm._e(), _vm.isExistDetailID && (!_vm.form.schedule.position_jetty || !_vm.form.schedule.position_baris_jetty) ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('b-alert', {
    staticClass: "mb-0 mt-1",
    attrs: {
      "variant": "warning",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_c('feather-icon', {
    staticClass: "mr-25",
    attrs: {
      "icon": "AlertTriangleIcon"
    }
  }), _vm._v(" Belum realisasi posisi jetty ")], 1)])], 1) : _vm._e()], 1)], 1) : _vm._e(), (_vm$form$tug_boat$use12 = _vm.form.tug_boat.user) !== null && _vm$form$tug_boat$use12 !== void 0 && _vm$form$tug_boat$use12.abilities.some(function (ability) {
    return ability.action === 'Hulu Migas';
  }) ? _c('validation-observer', {
    ref: "refRKA222",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', {
    staticClass: "p-3"
  }, [_vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "cols": "12"
    }
  }, [_c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('b-badge', {
    attrs: {
      "variant": 'info'
    }
  }, [_vm._v("Tug Boat")]), _c('h3', [_vm._v(" Realisasi Kapal " + _vm._s(this.form.tug_boat.name_tug_boat) + " ")]), _c('span', [_vm._v(_vm._s(this === null || this === void 0 ? void 0 : (_this$form_realisasi = this.form_realisasi) === null || _this$form_realisasi === void 0 ? void 0 : _this$form_realisasi.rka2))]), _c('br'), _c('span', [_vm._v("Sandar → Berangkat")])], 1)])]) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.$store.state.app.roleName !== 'viewer' && _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? '3' : '3',
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Sandar",
      "label-for": "vi-tug_boat-tug_sandar_start_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Sandar",
      "vid": "Waktu Kapal Sandar",
      "rules": {
        required_if: 'Waktu Kapal Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref35) {
        var errors = _ref35.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' || _vm.$store.state.app.roleName === 'security' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL SANDAR",
            "state": errors.length > 0 ? false : null,
            "config": _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.tug_boat.tug_sandar_start_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "tug_sandar_start_time", $$v);
            },
            expression: "form.tug_boat.tug_sandar_start_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-tug_boat-tug_sandar_start_time",
            "value": _vm.formatDateTime(_vm.form.tug_boat.tug_sandar_start_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly(),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 2788995912)
  })], 1)], 1) : _vm._e(), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": _vm.permissionSalOnly() ? '3' : '3',
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Tujuan Kapal",
      "label-for": "vi-asal_kapal"
    }
  }, [_c('div', {
    staticClass: "suggest-custom"
  }, [_c('validation-provider', {
    attrs: {
      "name": "Tujuan Kapal",
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref36) {
        var errors = _ref36.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-tujuan-kapal",
            "suggestions": [{
              data: _vm.form.dm_asal_kapal.dm_asal_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValueTKTB,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: 'Tujuan Kapal'
            }
          },
          on: {
            "focus": function focus($event) {
              return _vm.getSuggestionValueFocus($event, 'schedule', 'tujuan_kapal_tb');
            },
            "input": function input($event) {
              return _vm.searchForm({
                keyword: $event,
                uri: 'dm_asal_kapal/',
                model: 'dm_asal_kapal',
                currentSuggest: 'asal',
                flagTujuanOps: 'tujuan_kapal_tb_is_new'
              });
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref37) {
              var suggestion = _ref37.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('b-avatar', {
                attrs: {
                  "text": Array.from(suggestion.item.asal)[0] + Array.from(suggestion.item.asal)[1],
                  "variant": "primary"
                }
              }), _c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("".concat(suggestion.item.asal))
                }
              })]), _c('br'), _c('small', {
                staticClass: "text-muted"
              }, [_c('strong', [_c('span', {
                domProps: {
                  "textContent": _vm._s("Created AT ".concat(_vm.formatDateTime(suggestion.item.created_at)))
                }
              })])]), _c('br')])], 1)], 1)];
            }
          }], null, true),
          model: {
            value: _vm.form.schedule.tujuan_kapal_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "tujuan_kapal_tb", $$v);
            },
            expression: "form.schedule.tujuan_kapal_tb"
          }
        }, [_c('template', {
          slot: "before-suggestions"
        }, [_c('div', {
          staticClass: "ml-1 mt-1 mb-1 mr-1"
        }, [_c('b-badge', {
          staticClass: "mr-1",
          attrs: {
            "href": "#",
            "pill": "",
            "variant": _vm.statusSuggestionColor[_vm.dm_asal_kapalState]
          }
        }, [_c('span', [_vm._v(" " + _vm._s(_vm.statusSuggestionChoose[_vm.dm_asal_kapalState]))])]), _c('b-badge', {
          attrs: {
            "variant": "info"
          }
        }, [_vm._v(" Total: " + _vm._s(_vm.form.dm_asal_kapal.dm_asal_kapals.length) + " ")])], 1)])], 2), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))]), _vm.errorPath && _vm.errorMatchValue === _vm.form.dm_asal_kapal.asal ? _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(_vm.errorPath === 'asal' ? _vm.errorValue : ''))]) : _vm._e()];
      }
    }], null, false, 1846972412)
  }), _vm.dm_asal_kapalStateLoading ? _c('b-spinner', {
    staticClass: "suggest-loading",
    attrs: {
      "small": "",
      "variant": "primary"
    }
  }) : _vm._e()], 1)])], 1) : _vm._e(), _vm.isExistDetailID ? _c('b-col', {
    attrs: {
      "md": _vm.$store.state.app.roleName !== 'viewer' && _vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' ? '4' : '3',
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Kapal Berangkat",
      "label-for": "vi-tug_boat-tug_sandar_end_time"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Kapal Berangkat",
      "vid": "Waktu Kapal Berangkat",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref38) {
        var errors = _ref38.errors;
        return [_vm.permissionSalOnly() || _vm.$store.state.app.roleName === 'jetty man' || _vm.$store.state.app.roleName === 'security' ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "WAKTU KAPAL BERANGKAT",
            "state": errors.length > 0 ? false : null,
            "config": _vm.configs.end
          },
          on: {
            "on-change": _vm.onEndChange
          },
          model: {
            value: _vm.form.tug_boat.tug_sandar_end_time,
            callback: function callback($$v) {
              _vm.$set(_vm.form.tug_boat, "tug_sandar_end_time", $$v);
            },
            expression: "form.tug_boat.tug_sandar_end_time"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-tug_boat-tug_sandar_end_time",
            "value": _vm.formatDateTime(_vm.form.tug_boat.tug_sandar_end_time),
            "state": errors.length > 0 ? false : null,
            "readonly": _vm.permissionSalOnly(),
            "placeholder": "Diisi Admin SAL | Jetty Man"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 964419046)
  })], 1)], 1) : _vm._e(), _vm.$store.state.app.roleName !== 'viewer' ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "2",
      "cols": "12"
    }
  }, [_vm.isExistDetailID || _vm.$store.state.app.roleName === 'jetty man' ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRelRKA2
    },
    on: {
      "click": function click($event) {
        return _vm.saveRKA222();
      }
    }
  }, [!_vm.loadingRelRKA2 ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRelRKA2 ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi")])], 1) : _vm._e()], 1) : _vm._e()], 1), _c('div', {
    staticClass: "divider my-2"
  }, [_c('div', {
    staticClass: "divider-text"
  }, [_c('h3', [_vm._v("Realisasi Posisi Jetty")])])]), _c('b-alert', {
    staticClass: "mb-0",
    attrs: {
      "variant": "primary",
      "show": ""
    }
  }, [_c('div', {
    staticClass: "alert-body"
  }, [_vm._v(" Histori Kapal Awal Sandar / Pindah Jetty "), _c('strong', [_vm._v("Diisi Admin SAL / Jetty Man")])])]), _c('br'), _c('NestedIO', {
    key: _vm.form.table_jetty_position,
    attrs: {
      "uri": "realisasi_posisi_kapal",
      "model-id": _vm.dataID,
      "model": "form_tug_boat",
      "posisi": _vm.jetty_positions,
      "posisi-baris": _vm.jetty_baris_positions,
      "disable-action": !!this.form.tug_boat.tug_sandar_start_time,
      "sandar-time": _vm.form.tug_boat.tug_sandar_start_time,
      "payload": {
        model_id: null,
        model: null,
        is_first: null,
        posisi_awal: null,
        posisi_awal_baris: null,
        posisi_skrg: null,
        posisi_skrg_baris: null,
        reason: null,
        waktu_pindah: null
      },
      "column-repeat": [{
        fe_id: 1
      }],
      "d-t-o": [],
      "resolve-fetch": function resolveFetch(response) {
        return response.data.realisasi_posisi_kapals;
      },
      "buttonOkTitle": " Simpan Realisasi"
    },
    on: {
      "sentNotification": _vm.sentNotification,
      "alreadyRealisasi": _vm.alreadyRealisasi,
      "alreadyFetch": _vm.alreadyFetch
    }
  }), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e(), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e(), _vm.isExistDetailID && _vm.form.schedule.kegiatan !== 'BUNKER' ? _c('tab-content', {
    attrs: {
      "title": _vm.wizard.second.step3.title,
      "icon": _vm.wizard.second.step3.icon
    }
  }, [_c('b-row', [_c('b-col', {
    staticClass: "mb-2",
    attrs: {
      "cols": "12"
    }
  }, [_c('h5', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.wizard.second.step3.content.title))]), _c('small', {
    staticClass: "text-muted"
  }, [_c('strong', [_vm._v(_vm._s(_vm.wizard.second.step3.content.subtitle) + ".")])])])], 1), _c('span', [_c('validation-observer', {
    ref: "refSAL",
    attrs: {
      "tag": "form"
    }
  }, [_c('b-row', [_c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-card', [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.phrase.title_persetujuan))]), _c('hr'), _c('b-row', [_c('b-col', {
    attrs: {
      "md": "4",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[1] Status Kapal Sesuai INAPORTNET *",
      "label-for": "vi-schedule-status_kapal_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "[1] Status Kapal Sesuai INAPORTNET *",
      "vid": "[1] Status Kapal Sesuai INAPORTNET *",
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref39) {
        var errors = _ref39.errors;
        return [_c('vue-autosuggest', {
          directives: [{
            name: "uppercase",
            rawName: "v-uppercase"
          }],
          attrs: {
            "id": "vi-schedule-status_kapal_tb",
            "suggestions": [{
              data: _vm.status_kapals
            }],
            "get-suggestion-value": _vm.getSuggestionValueManualTB,
            "state": errors.length > 0 ? false : null,
            "input-props": {
              id: 'autosuggest__input',
              class: 'form-control',
              placeholder: "Pilih / Ketik Manual",
              disabled: false
            }
          },
          on: {
            "input": function input($event) {
              return _vm.getSuggestionValueManualInputTB($event);
            }
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref40) {
              var suggestion = _ref40.suggestion;
              return [_c('div', {
                staticClass: "d-flex align-items-center"
              }, [_c('div', {
                staticClass: "detail ml-50"
              }, [_c('b-card-text', {
                staticClass: "mb-0"
              }, [_c('strong', [_c('span', [_vm._v(" " + _vm._s(suggestion.item.status_kapals))])])])], 1)])];
            }
          }], null, true),
          model: {
            value: _vm.form.schedule.status_kapal_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "status_kapal_tb", $$v);
            },
            expression: "form.schedule.status_kapal_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]) + " ")])];
      }
    }], null, false, 1109433582)
  })], 1)], 1), _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[2] No Pindah",
      "label-for": "vi-schedule-no_pindah_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "No Pindah",
      "vid": "No Pindah",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref41) {
        var errors = _ref41.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_pindah_tb",
            "state": errors.length > 0 ? false : null,
            "placeholder": 'Nomor Pindah (opsional)'
          },
          model: {
            value: _vm.form.schedule.no_pindah_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_pindah_tb", $$v);
            },
            expression: "form.schedule.no_pindah_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3746004546)
  })], 1)], 1), _vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$can('TBBM JETTY 3A', '') || _vm.$router.currentRoute.params.id ? _c('b-col', {
    attrs: {
      "md": "4",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[3] Nomor RPKRO *",
      "label-for": "vi-schedule-no_rpkro"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor RPKRO *",
      "vid": "Nomor RPKRO *",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref42) {
        var errors = _ref42.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_rpkro",
            "state": errors.length > 0 ? false : null,
            "placeholder": 'Ex: IDSRI-SALPAL-01153'
          },
          model: {
            value: _vm.form.schedule.no_rpkro_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_rpkro_tb", $$v);
            },
            expression: "form.schedule.no_rpkro_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3014447615)
  })], 1)], 1) : _vm._e(), (_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$router.currentRoute.params.id) && ((_vm$form$tug_boat$use13 = _vm.form.tug_boat.user) === null || _vm$form$tug_boat$use13 === void 0 ? void 0 : (_vm$form$tug_boat$use14 = _vm$form$tug_boat$use13.abilities[0]) === null || _vm$form$tug_boat$use14 === void 0 ? void 0 : _vm$form$tug_boat$use14.action) == 'TBBM JETTY 3A' ? _c('b-col', {
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "[4] Nomor PPK *",
      "label-for": "vi-schedule-no_ppk_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Nomor PPK *",
      "vid": "Nomor PPK *",
      "rules": {
        required: false
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref43) {
        var errors = _ref43.errors;
        return [_c('b-form-input', {
          attrs: {
            "id": "vi-schedule-no_ppk_tb",
            "state": errors.length > 0 ? false : null,
            "placeholder": ''
          },
          model: {
            value: _vm.form.schedule.no_ppk_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "no_ppk_tb", $$v);
            },
            expression: "form.schedule.no_ppk_tb"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 256225889)
  })], 1)], 1) : _vm._e()], 1)], 1)], 1), _vm.form.schedule.jenis_kapal && (_vm.form.schedule.kegiatan === 'BUNKER' || _vm.form.schedule.kegiatan_tambahan === 'BUNKER') ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Mulai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_start_time_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Mulai Berkegiatan",
      "vid": "Waktu Mulai Berkegiatan",
      "rules": {
        required_if: 'Waktu Selesai Berkegiatan'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref44) {
        var errors = _ref44.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$can('TBBM JETTY 3A', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "-",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpconfigRevisi : _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.schedule.kegiatan_start_time_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_start_time_tb", $$v);
            },
            expression: "form.schedule.kegiatan_start_time_tb"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_start_time_tb",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_start_time_tb),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "-"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 3189396550)
  })], 1)], 1) : _vm._e(), _vm.form.schedule.jenis_kapal && (_vm.form.schedule.kegiatan === 'BUNKER' || _vm.form.schedule.kegiatan_tambahan === 'BUNKER') ? _c('b-col', {
    attrs: {
      "md": "6",
      "cols": "6"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Waktu Selesai Berkegiatan",
      "label-for": "vi-schedule-kegiatan_end_time_tb"
    }
  }, [_c('validation-provider', {
    attrs: {
      "name": "Waktu Selesai Berkegiatan",
      "vid": "Waktu Selesai Berkegiatan",
      "rules": {
        required_if: 'Waktu Berangkat'
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref45) {
        var errors = _ref45.errors;
        return [_vm.$can('manage', 'all') || _vm.$can('SAL', '') || _vm.$can('TBBM JETTY 3A', '') ? _c('flat-pickr', {
          staticClass: "form-control",
          attrs: {
            "static": "true",
            "placeholder": "-",
            "state": errors.length > 0 ? false : null,
            "config": _vm.$router.currentRoute.params.id !== undefined ? _vm.dpconfigRevisi : _vm.dpconfig
          },
          on: {
            "on-change": _vm.onStartChange
          },
          model: {
            value: _vm.form.schedule.kegiatan_end_time_tb,
            callback: function callback($$v) {
              _vm.$set(_vm.form.schedule, "kegiatan_end_time_tb", $$v);
            },
            expression: "form.schedule.kegiatan_end_time_tb"
          }
        }) : _c('b-form-input', {
          attrs: {
            "id": "vi-schedule-kegiatan_end_time_tb",
            "value": _vm.formatDateTime(_vm.form.schedule.kegiatan_end_time_tb),
            "state": errors.length > 0 ? false : null,
            "readonly": !_vm.$can('manage', 'all') && _vm.$can('manage', 'special') || !_vm.$can('SAL', ''),
            "placeholder": "-"
          }
        }), _c('small', {
          staticClass: "text-danger"
        }, [_vm._v(_vm._s(errors[0]))])];
      }
    }], null, false, 1808776521)
  })], 1)], 1) : _vm._e(), _vm.$store.state.app.roleName !== 'viewer' ? _c('b-col', {
    staticClass: "text-right",
    attrs: {
      "md": "12",
      "cols": "12"
    }
  }, [_vm.isExistDetailID && (_vm.permissionSalOnly() || _vm.$can('TBBM JETTY 3A', '')) ? _c('b-button', {
    staticClass: "mt-2",
    attrs: {
      "variant": "gradient-primary",
      "block": "",
      "disabled": _vm.loadingRealisasiKegiatan
    },
    on: {
      "click": function click($event) {
        return _vm.saveRealisasiKegiatan('');
      }
    }
  }, [!_vm.loadingRealisasiKegiatan ? _c('feather-icon', {
    staticClass: "mr-50",
    style: {
      cursor: 'pointer'
    },
    attrs: {
      "icon": "CheckCircleIcon"
    }
  }) : _vm._e(), _vm.loadingRealisasiKegiatan ? _c('b-spinner', {
    attrs: {
      "small": ""
    }
  }) : _vm._e(), _c('span', {
    staticClass: "align-middle"
  }, [_vm._v("Simpan Realisasi Kegiatan")])], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "mb-1"
  })], 1) : _vm._e()], 1)], 1), _vm.$router.currentRoute.params.id !== undefined ? _c('div', {
    staticClass: "text-center"
  }, [_c('b-button', {
    attrs: {
      "variant": "success",
      "to": {
        name: 'dashboard-monitoring-rrv-tug-boat-summary-j3a',
        params: {
          id: _vm.form.form_tug_boat_id,
          api: 'form_tug_boat',
          previous_link: _vm.$router.currentRoute
        }
      }
    }
  }, [_c('feather-icon', {
    attrs: {
      "icon": "EyeIcon"
    }
  }), _vm._v(" Summary ")], 1), _c('hr'), _c('b-badge', {
    staticClass: "mr-1 mb-1 text-center",
    attrs: {
      "href": "#",
      "pill": "",
      "variant": "secondary"
    }
  }, [_c('strong', [_c('span', {
    domProps: {
      "textContent": _vm._s("Form Perencanaan Created BY ".concat(_vm.form.created_by))
    }
  })]), _c('br')]), _c('br'), _vm._v(" Created AT " + _vm._s(_vm.formatDateTime(_vm.form.schedule.created_at)) + " - Last Updated AT " + _vm._s(_vm.formatDateTime(_vm.form.schedule.updated_at)) + " ")], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }